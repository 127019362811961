import axios from '@/libs/axios';

const apiEndpoint = '/auth/games';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async getById ({}, {id}) {
            try {
                return (await axios.get(apiEndpoint+'/'+id)).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async getPlayers ({}, {id, teamId}) {
            try {
                return (await axios.get(apiEndpoint+'/'+id+'/players/'+teamId)).data.data || [];
            }
            catch (e) {
                console.log(e);
                return [];
            }
        },
        async getReferees ({}, {id}) {
            try {
                return (await axios.get(apiEndpoint+'/'+id+'/referees')).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async getReport ({}, {gameId}) {
            try {
                return (await axios.get(apiEndpoint+'/'+gameId+'/game-reports')).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async saveReport ({}, {gameId,properties}) {
            try {
                return (await axios.post(apiEndpoint+'/'+gameId+'/game-reports/save',
                        {properties}
                )).data.data;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async addReferee ({}, {id, refereeId, refereeType, refereeFunction}) {
            try {
                return (await axios.post(apiEndpoint+'/'+id+'/referees', {
                    id: refereeId,
                    type: refereeType,
                    refereeFunction: refereeFunction,
                })).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async removeReferee ({}, {id, refereeType}) {
            try {
                return (await axios.delete(apiEndpoint+'/'+id+'/referees/'+refereeType)).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async getScore ({}, {id}) {
            try {
                return (await axios.get(apiEndpoint+'/'+id+'/score')).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async setScore ({}, {id, score}) {
            try {
                return (await axios.post(apiEndpoint+'/'+id+'/score', score)).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async getStatuses ({}) {
            return [
                {id: 1, name: 'Uvožena'},
                {id: 2, name: 'Delegirana'},
                {id: 3, name: 'Zaključena'},
                {id: 4, name: 'Potrjena'},
                {id: 5, name: 'Razveljavljena'},
            ];
        },
        async validateTeamPlayers({}, {players, captains, liberos, rules}) {
            return {
                playersValid: (!rules.playerLimitCheck && players.length > 0) || (players.length > 0 && players.length <= 14),
                captainValid: captains.length === 1,
                liberosValid: !rules.liberoCheck || (liberos.length <= 2 && (players.length <= 12 || liberos.length === 2)),
            };
        },
        async validateVolleyballGame({}, {rules, homeSets, guestSets}) {
            if (homeSets.length !== guestSets.length || homeSets.length > rules.maxSets || guestSets.length > rules.maxSets) {
                return false;
            }

            let homeWins = 0;
            let guestWins = 0;

            for (let i = 0; i < homeSets.length; i++) {
                const isFinalSet = (homeWins === (rules.setsRequiredToWin - 1) && guestWins === (rules.setsRequiredToWin - 1));

                const homePoints = Math.floor(Number(homeSets[i] || 0));
                const guestPoints = Math.floor(Number(guestSets[i] || 0));
                if (!validateVolleyballSet(rules, homePoints, guestPoints, isFinalSet)) {
                    return false;
                }

                if (homePoints > guestPoints) {
                    homeWins++;
                }
                else {
                    guestWins++;
                }

                if (homeWins === rules.setsRequiredToWin || guestWins === rules.setsRequiredToWin) {
                    return true;
                }
            }

            return homeWins === rules.setsRequiredToWin || guestWins === rules.setsRequiredToWin;
        },
    },
}

function validateVolleyballSet(rules, homeScore, guestScore, isFinalSet) {
    const winScore = isFinalSet ? rules.finalSetScore : rules.winSetScore;

    if (homeScore < 0 || guestScore < 0) {
        return false;
    }

    const scoreDiff = Math.abs(homeScore - guestScore);
    if (scoreDiff < 2) {
        return false;
    }

    return (homeScore >= winScore && homeScore > guestScore) || (guestScore >= winScore && guestScore > homeScore);
}
